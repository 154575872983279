import React from 'react';
import Link from 'next/link';
import styles from './index.module.less';
import Image from 'next/image';

interface OtherLandingProps {
  data?: any;
}

const OtherLanding: React.FC<OtherLandingProps> = ({ data }) => {
  const landingOptions = [
    {
      id: 1,
      title: 'Hair Color Try-On',
      description: 'Virtually try on different hair colors to find your perfect match.',
      image: '/images/landing/colors.webp',
      link: 'virtual-hair-color-try-on',
    },
    {
      id: 2,
      title: 'Good Haircut for Your Face Shape',
      description: 'Find the most flattering haircut based on your face shape.',
      image: '/images/landing/shapes.webp',
      link: 'best-hairstyles-for-face-shape',
    },
    {
      id: 3,
      title: 'Hairstyles for All Hair Types',
      description:
        'Explore different haircuts and styles tailored to straight, wavy, curly, and coily hair types.',
      image: '/images/landing/hair_types.webp',
      link: 'hair-types',
    },
    {
      id: 4,
      title: 'Trending Hair Styles',
      description: 'Explore the latest hairstyle trends and get inspired for your next look.',
      image: '/images/landing/trending.webp',
      link: 'trending-hairstyles',
    },
  ];
  const filterLandingOptions = landingOptions.filter((option) => option.link !== data?.pathId);
  return (
    <div className={styles.otherLandingContainer}>
      <div className={styles.title}>
        <h2>Virtual Hair Tools to Try On Your Perfect Look</h2>
      </div>

      <div className={styles.optionsContainer}>
        {filterLandingOptions.map((option) => {
          return (
            <Link href={'/' + option.link} key={option.id}>
              <div className={styles.optionCard}>
                <div className={styles.imageWrapper}>
                  <Image src={option.image} alt={option.title} width={396} height={286} />
                </div>
                <div className={styles.optionContent}>
                  <h3>{option.title}</h3>
                  <p>{option.description}</p>
                  <div className={styles.tryNow}>
                    <span>Try now</span>
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5 12H19"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12 5L19 12L12 19"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default OtherLanding;
